import { Link } from 'gatsby';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadein = keyframes`
    {
        0%      { transform: translateY(100px); }
        100% { webkit-transform: translateY(0%)); }
    }
`;

const Cta = styled.button`
    padding: 1em 2.5em;
    background-color: ${props => props.isClosed ? '#adadad' : '#e03df6'};
    color: ${props => props.isClosed ? 'black' : 'white'};
    border: none;
    margin-top: ${props => props.marginTop};
    margin-bottom: .5em;
    cursor: ${props => props.isClosed ? 'not-allowed' : 'pointer'};
    font-size: .7em !important;
    align-self: center;
    width: ${props => props.width};
    letter-spacing: 1.5px;
    font-size: 1em;
    font-weight: bold;
    animation: ${fadein} .3s linear;

    &:hover {
        transition: background ease 0.35s, border ease 0.35s,
      transform cubic-bezier(0.47, 2.02, 0.31, -0.36) 0.35s;
      transform: scale(0.9);
    }

    @media screen and (max-width: 980px) {
        background-color: ${props => props.isClosed ? '#adadad' : '#5e6ecc'};    
    }
`;

const AnchorLink = styled(Link)`
    text-decoration: none;
    color: ${props => props.isClosed ? 'black' : 'white'};
`;

const Back = styled(Link)`
    position: absolute;
    top: 7%;
    left: 2%;
    color: #f7f7f7;

    @media screen and (min-width: 320px) and (max-width: 1024px) {
        top: 3%;
        font-size: .8em;    
    }
`

const Button = ({ anchorLink, ctaType, hasLink, isClosed, marginTop, value, width }) => {
    if (hasLink) {
        return (
            <Cta
                ctaType={ctaType} 
                disabled={isClosed}
                isClosed={isClosed}
                marginTop={marginTop}
                width={width}>
                    <AnchorLink isClosed={isClosed} target="_blank" to={anchorLink}>
                        {value}
                    </AnchorLink>
            </Cta>
        );
    }
    return <Cta disabled={isClosed} isClosed={isClosed} ctaType={ctaType} marginTop={marginTop} width={width}>{value}</Cta>;
}

export const BackButton = ({ text }) => <Back to="/"> &#8592; {text}</Back>

export default Button;
