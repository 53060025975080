import styled from 'styled-components';

export const Header = styled.div`
    background: rgb(224,61,246);
    background: linear-gradient(90deg, rgba(224,61,246,1) 0%, rgba(128,127,247,1) 35%, rgba(130,189,255,1) 100%);
    padding: .5em 1em;
    height: auto;
    text-align: center;
    font-weight: bolder;
    font-size: 2.5em;
    color: white;
    letter-spacing: 1px;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        font-size: 1.8em;
    }
`;